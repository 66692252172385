import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Img from "gatsby-image"
import SEO from "../../components/seo"
import "../../styles/styles.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMap, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import ContactEstimateForm from "../../components/contactEstimateForm"
import { globalHistory } from "@reach/router"
import RequestQuoteForm from "../../components/requestQuoteForm"

const LettersPage = ({ data }) => {
  const { pathname, href } = globalHistory.location
  return (
    <Layout>
      <SEO title="Direct Mail Letters" />
      <section className="hero is-medium has-bg-img">
        <div className="hero-body">
          <div className="container">
            <div className="has-text-centered">
              <span className="is-size-1 has-text-white has-text-weight-bold">
                Direct Mail Letters
              </span>
              <p className="is-size-4 has-text-white mb-1">
                Targeted, personal, relevant direct mail campaigns. Call now for
                a free quote!
              </p>
              <a
                className="button is-danger is-large mt-3"
                href="tel:253-872-3552"
              >
                Call Us Now!
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <nav className="breadcrumb mt-3" aria-label="breadcrumbs">
          <ul>
            <li>
              <Link to="/direct-mail">Services</Link>
            </li>
            <li className="is-active">
              <Link to={"#"} aria-current="page">
                Direct Mail Letters
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <section>
        <div className="container pt-5 px-3">
          <div className="columns is-vcentered">
            <div className="column is-5">
              <h2 className="has-text-black is-size-1 has-text-weight-bold mb-3">
                Direct Mail Letters
              </h2>
              <div className="seprator-line" />
              <Img fluid={data.file.childImageSharp.fluid} />
              <p className="mb-3 is-size-4">
                Direct mail letters are a great tool to use if you have more
                content to promote. Larger in size than most postcards, letters
                will allow you to get your message across and not look
                overcrowded, which can lower response rates. Letters will be
                printed on high quality paper stock, and inserted into quality
                envelopes which will help you stand out from other pieces in the
                mailbox. We have plenty of size and quantity options to
                perfectly match your budget and project needs
              </p>
            </div>
            <div className="column is-6 is-offset-1">
              <RequestQuoteForm location={"postcard-page"} service="letter" />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-6 pt-5 px-3">
          <div className="columns is-vcentered">
            <div className="column is-4 has-text-centered">
              <h3 className="is-size-3 mb-3">Premium Paper Stocks</h3>
              <p>
                We use premium paper stocks, which help you stand out in the
                mailbox.
              </p>
            </div>
            <div className="column is-4 has-text-centered">
              <h3 className="is-size-3 mb-3">Postcard Design Services</h3>
              <p>
                Tap into our 40 plus years of experience to design letters that
                will generate responses and ROI.
              </p>
            </div>
            <div className="column is-4 has-text-centered">
              <h3 className="is-size-3 mb-3">Full Service Direct Mail</h3>
              <p>
                We will print, address, prep, and deliver your letter for you so
                you can save time and relax.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="hero mt-6 is-medium is-primary">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-6">
                <span className="is-size-1 has-text-white">Get In Touch!</span>
                <div className="seprator-line" />
                <p className="has-text-weight-bold is-size-4 mt-6">
                  <FontAwesomeIcon icon={faMap} size="1x" className="mr-3" />{" "}
                  Address
                </p>
                <p className="is-size-5">
                  21319 68th Avenue South Kent, WA 98032
                </p>
                <hr />
                <p className="has-text-weight-bold is-size-4 mt-6">
                  <FontAwesomeIcon icon={faPhone} size="1x" className="mr-3" />{" "}
                  Phone
                </p>
                <p className="is-size-5">
                  <a href="tel:253-872-3552">253-872-3552</a>
                </p>
                <hr />
                <p className="has-text-weight-bold is-size-4 mt-6">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    size="1x"
                    className="mr-3"
                  />{" "}
                  Email
                </p>
                <p className="is-size-5">
                  <a href="mailto:chad@mail-ad.com">info@mail-ad.com</a>
                </p>
              </div>
              <div className="column is-5 is-offset-1">
                <ContactEstimateForm location={pathname} href={href} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LettersPage

export const pageQuery = graphql`
  query DirectMailLetterQuery {
    file(absolutePath: { regex: "/direct-mail-letters/" }) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 285) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
